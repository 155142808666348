import { useState } from "react";

const Comments = () => {
    const comments = [
        {
            id: 0,
            author: "Casual Bob (Yotty Laboratories)",
            text: "I was lucky enough to be one of the early testers of Cafeteria. Seeing what's been achieved already, I'm simply blown away. It's so straight forward to use, and it just works. From a user perspective, it's a nice place to visit and just spend time. When I learned there was an opportunity to invest in Cafeteria I couldn't put my name forward fast enough. Having learned some of things they have in the pipeline, it sells itself.",
            donation_value: "2500"
        }
    ]

    const [activeComment, setActiveComment] = useState(comments[0]);

    const changeComment = (direction) => {
        if (comments.length > 1){
            if (direction === "forward") {
                if ((comments.length - 1) > activeComment.id){
                    setActiveComment(comments[activeComment.id + 1])
                } else {
                    setActiveComment(comments[0]); // Start at first again
                }
            } else {
                if (activeComment.id === 0) {
                    setActiveComment(comments[comments.length - 1]); // Go to last comment
                } else {
                    setActiveComment(comments[activeComment.id - 1]);
                }
            }
        }
    };
    return (
        <div className="comments-container">
            <button className="move-comment" onClick={() => changeComment("backward")}>
                <img src="/images/arrow-back.png" alt="arrow-back"/>
            </button>
            {
                activeComment?
                    <div className="comment" key={activeComment.id}>
                        <div className="comment-author">
                            <h3 className="comment-name">{activeComment.author}</h3>
                            <span className="donation-value">${Number(activeComment.donation_value).toLocaleString()}</span>
                        </div>
                        <p>{activeComment.text}</p>
                    </div>
                : ''
            }
            <button className="move-comment" onClick={() => changeComment("forward")}>
                <img src="/images/arrow-forward.png" alt="arrow-forward"/>
            </button>
        </div>
    );
};

export default Comments;
