import { useState } from 'react';
import './App.scss';
import './AppPrivate.scss';
import FAQ from './components/FAQ';
import Comments from "./components/Comments";
import * as bcrypt from 'bcryptjs';

const BCRYPT_PASSWORD_HASH = '$2a$12$68Bhxw1voALc6sOfsPyzBOsoiorUxb7OTxkW74SXt/M6fAr/3XntC';

function App() {
    let raised = 2850;
    let goal = 15000;
    const progress = (raised / goal) * 100;

    const redirectToForm = () => {
        window.open("https://docs.google.com/forms/d/1G-VfMUYTmigGoNgmE_q_PCEKQBfxX8FjxxStD0bx3zc/viewform", "_blank");
    };

    const redirectToPaymentForm = () => {
        window.open("https://forms.gle/BreF5bYo5ESHSZkk8", "_blank");
    };

    const redirectToStripeCheckout = () => {
        window.open("https://checkout.stripe.com/c/pay/cs_live_a1BoB3OTh9uY3AIYmAvJxGgAHijBOGsFgr1SmnqXLL5tQWRANvQ6U6Rfz8#fidkdWxOYHwnPyd1blppbHNgWjA0SGdHbXFEN0lSTWdqUTZTf05gUD1sbGBPPDd9Q1ViYEY2QXdgaUBgPV1LaXF8QFRWSUJ1N11BUzRDbG1dTGpqf0tgV1dyZ0pOX1RUV31Af3JUR2A2bklKNTV0d2g8ZkQ9NicpJ3VpbGtuQH11anZgYUxhJz8nNDFuYjUxNUtONlQ1MnIxMWJiJyknd2BjYHd3YHdKd2xibGsnPydtcXF1dj8qKnJycitiampiaWArZmpoKid4JSUl", "_blank");
    };

    const [isPrivate, setIsPrivate] = useState(window.localStorage.getItem('skipPassword') ? false : true);
    const [isWrongPassword, setIsWrongPassword] = useState(false);
    const [password, setPassword] = useState("");

    const getDaysLeft = (endDate) => {
      const oneDay = 24 * 60 * 60 * 1000
      return Math.ceil((endDate - new Date()) / oneDay);
    }

    const endDate = new Date('3/20/2023');
    const daysLeft = getDaysLeft(endDate);

    const onValidatePassword = (e) => {
        // eslint-disable-next-line
        if (e.type === "click" || e.type === "keydown" && e.keyCode === 13) {
            return new Promise((resolve, reject) => {
                bcrypt.compare(password, BCRYPT_PASSWORD_HASH).then((res) => {
                    if (res) {
                        document.title = 'Cafeteria - Fundraising';
                        setIsWrongPassword(false);
                        window.localStorage.setItem('skipPassword', true);
                    } else {
                        setIsWrongPassword(true);
                    }
                    setIsPrivate(!res);
                    resolve(true);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });
            });
        }
    };

    if (isPrivate) {
        return (<div className="App-Private">
            <div className="mx_PasswordDialog">
                <div className="mx_PasswordDialog_Wrap">
                    <div className="mx_PasswordDialog_Wrap_Header"><div className="mx_PasswordDialog_Wrap_title">What's the Password?</div><div className="mx_PasswordDialog_Wrap_subTitle">To gain access, please enter the password we provided you...</div></div>
                    <div className="mx_Field mx_Field_input mx_PasswordDialog_Wrap_passwordInput"><input label="Password" type="password" placeholder="Password" id="mx_Field_3" maxLength="200" onChange={(e) => setPassword(e.target.value)} onKeyDown={onValidatePassword}></input></div>
                    <button className="mx_PasswordDialog_Wrap_signButton" onClick={onValidatePassword}>Sign In</button>
                    <span className={(isWrongPassword ? '' : 'd-none')}>Sorry, that password is incorrect!</span>
                </div>
            </div>
        </div>)
    } else {
        return (
            <div className="App">
                <header>
                    <img src="/images/update.png" alt="cafeteria-update" /><h3>Friends &amp; Family Raise</h3>
                    <div className="line-break"></div>
                    <button className="header-support" onClick={redirectToForm}>Support Cafeteria</button>
                </header>
                <div className="hero-poster"></div>
                <div className="goal-raised container-shadow">
                    {/* <span className="gr raised">Raised: <span className="bold">$1,200</span></span>
                <span className="gr goal">Goal: <span className="bold">$25,000</span></span> */}
                    <div className="progress-wrap progress">
                        <div className="progress-bar progress" style={{ width: progress + '%' }}><span className="bold" style={{ display: progress >= 25 ? 'block' : 'none' }}>${raised.toLocaleString()}</span></div>
                    </div>
                    <h4 style={{ display: progress >= 25 ? 'none' : 'block' }}>RAISED:&nbsp;<span className="bold">${raised.toLocaleString()}</span></h4>
                    <h4>GOAL:&nbsp;<span className="bold">${goal.toLocaleString()}</span></h4>
                </div>
                <div className="donators-comments container-shadow">
                    <Comments />
                </div>
                <div className="video-offer container-shadow">
                    <div className="video">
                        <iframe width="80%" height="485" src="https://www.youtube.com/embed/Z-5V2w7FNPw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="offer">
                        <p>Cafeteria is a web2 web3 crossover chat platform that enables group owners to build communities, reward participation and monetise their audience.</p>
                        <p>We are raising <span className="bold">$15,000</span> in order to get our platform to the next stage where we can get seed funding.</p>
                        <p>We are offering up to <span className="bold">1% equity</span> of our company with <span className="bold">special perks included!</span></p>
                        <p>Jace Sparks of QYOU MEDIA, one of our strategic advisors shares what Cafeteria is all about…</p>
                        <div className="space"></div>
                        { daysLeft > 0 && <p><span className="full-line big-text bold">{daysLeft}</span> days to go...</p> }
                        <div className="space"></div>
                        <div className="action-buttons">
                            <button onClick={redirectToForm}>(1) Complete the form</button>
                            <button onClick={redirectToPaymentForm}>(2) Choose a payment method</button>
                            <button onClick={redirectToStripeCheckout}>(2b) Or pay with<img src="/images/stripe.svg" alt="Stripe" /></button>
                        </div>
                        <p>We accept crypto and direct card payments. Please check FAQ section below for more info, or <a target="_blank" rel="noreferrer" href="mailto:contact@cafeteria.gg">contact us</a> directly with any questions.</p>
                    </div>
                </div>
                <div className="about-cafeteria container-shadow center-container">
                    <div className="content-container">
                        <span className="about-title title">📍 Where we're at</span>
                        <p className="about-paragraph">Cafeteria started in the latter stages of 2021. After gaining the attention of Solana and receiving a 25k Grant, Richie left his other jobs to work on it full time and recruited Lee Hardy and Trevor Wills to assist with product development and operations. Two years later and mostly self funded by Richie, with additional funds from U.K. RnD tax credits, family financing and a pre-seed investment from Justine Ezarik, we now have a closed beta and solid team that has grown to include a full time CTO and non-exec advisors from key industries. Justine’s (iJustine) inclusion as a brand advisor and ambassador will help promote Cafeteria to her 10M+ followers and wider influencer network and contacts once our mobile app is in place is a key part of our marketing strategy and the upcoming seed round where she will play a leading role.</p>
                        <p className="about-paragraph">Right now we are at a crossroads where we are almost ready to open to the public with a total company valuation of 2.5m, but still lack some features needed to get to our seed round (including mobile), where our advisors anticipate an estimated evaluation of 8-10m. In order to achieve the next steps and be ready to pitch to top tier companies/investment firms we need to secure more runway.</p>
                        <p className="about-paragraph">We have several key seed stage/series 1 investors lined up with whom we have built a relationship and kept connected to our development journey over the past 12 - 18 months.  These include a large U.S. based media company with a very large growing audience in India of a few hundred million.</p>
                        <p className="about-paragraph">Cafeteria is built, you can check it out for yourself using the access information at the end of this document. We will be coming out of closed Beta (testing phase) shortly and are a few key features away from going to the aforementioned Seed round.  This is where you come in, as a possible supporter!</p>
                        <p className="about-paragraph">Please <a href="https://drive.google.com/drive/folders/1atbefsfPX4lE7xk5xvtFx6KDIexoJbQt?usp=sharing" target="_blank" rel="noreferrer">click here</a> to see our Pitch Deck and One Pager!</p>
                    </div>
                </div>
                <div className="cafeteria-faq container-shadow center-container">
                    <div className="content-container">
                        <FAQ />
                    </div>
                </div>
                <footer>
                    <div className="content-container">
                        <div className="footer-text">
                            <p>&copy; 2023 Cafeteria.GG Ltd. All rights reserved.</p><img src="/images/verification-caf.svg" alt="cafeteria-verification" />
                        </div>
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/CafeteriaGG" className="social-link"><img src="/images/twitter.svg" alt="Twitter" /></a>
                        <ul>
                            <li><a target="_blank" rel="noreferrer" href="https://cafeteria.gg/#/privacy">Privacy</a></li>
                            <li><a target="_blank" rel="noreferrer" href="/docs/TermSheet.pdf">Terms</a></li>
                        </ul>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
